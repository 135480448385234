import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './pages/about';
import Experience from './pages/experience';
import Portfolio from './pages/portfolio';
import Contact from './pages/contact';
import Awards from './pages/awards';
import ResponsiveAppBar from './compnents/ResponsiveAppBar';


function App() {
  return (
    <div className="App">
      {<Router>
        <ResponsiveAppBar />
        <Routes>
          <Route path='/' exact element={<About />} />
          <Route path='/about' element={<About />} />
          <Route path='/experience' element={<Experience />} />
          <Route path='awards' element={<Awards />} />
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
      </Router>}
    </div>
  );
}

export default App;
