import React from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

const Awards = () => {
    return (
        <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>

            <div>
                <h1>Awards</h1>
                <Typography variant="subtitle1" sx={{
                    paddingLeft: '20%',
                    paddingRight: '20%',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}>Over the pass few years, I have achieved outstanding results in science, technology and innovation related comptetions. I have received 15 awards from state to international level in those competitions, including a 1st Prize in an international UAV event.</Typography>

            </div>

            {/* <img src='/images/awards.jpg' style={{ maxWidth: '80%' }}></img> */}
            <Divider variant="middle" />

            <div class="main"
                style={{
                    textAlign: 'left',
                    paddingLeft: '10%',
                    paddingRight: '10%',
                }}>
                <h2>2019</h2>
                <p>First Prize - The First International Youth UAV Science Camp &amp; Competition</p>
                <p>2nd Runner Up - Ubtech Robotic Competition Smart Factory</p>
                <p>Ambassador - Cybersecurity Immersion for Youth Bootcamp</p>
                <p>Consolation Prize - IET Faraday Challenge</p>
                <p>Silver - Malaysian Computing Challenge</p>
                <p>Top Achiever - The University of St Andrews Mathematics Challenge</p>

                <h2>2018</h2>
                <p>Overall Champion - Tan Kah Kee Young Inventors Award</p>
                <p>Gold Medal - Tan Kah Kee Young Inventors Award</p>

                <h2>2017</h2>
                <p>Best market research - Young Outliers Game Changers Summer</p>
                <p>Most Cooperative Team - Digital Ninja Accelerator Labs</p>
                <p>Overall Champion - Tan Kah Kee Young Inventors Award</p>
                <p>Bronze Medal - Tan Kah Kee Young Inventors Award</p>
                <p>Top achievement - Cambridge English preliminary</p>

                <h2>2016</h2>
                <p>3rd Prize - ACCCIM STI Malaysia</p>
                <p>Overall Champion - Tan Kah Kee Young Inventors Award</p>
                <p>Commendation Prize - Tan Kah Kee Young Inventors Award</p>
            </div>
        </div>
    );
};

export default Awards;
