import React from 'react';
import IconButton from '@mui/material/IconButton';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Contact = () => {
	return (
		<div>
			<h1>Contact Me</h1>
			<IconButton aria-label="Facebook" href='https://www.facebook.com/people/Zhe-Ying-Chin/100011075083248/'>
				<FacebookIcon fontSize='large' />
			</IconButton>
			<IconButton aria-label="Instagram" href=''>
				<InstagramIcon fontSize='large' />
			</IconButton>
			<IconButton aria-label="LinkedIn" href='www.linkedin.com/in/zheyingchin/'>
				<LinkedInIcon fontSize='large' />
			</IconButton>
			<h3>Email: <a href='mailto:czy@zychin.com'>czy@zychin.com</a></h3>
			
		</div>
	);
};

export default Contact;
