import React from 'react';
import CustomizedTimeline from '../compnents/Timeline';

const Experience = () => {
    return (
        <div>

            <h1>Experience</h1>
            <CustomizedTimeline />
            
        </div>
    );
};

export default Experience;
