import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ResponsiveGallery from 'react-responsive-gallery';
import Paper from '@mui/material/Paper'

export default function MediaCard({ title, year, description, imagepath, gallery, link }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <Card sx={{ maxWidth: 345, margin: 1 }} variant="elevation" elevation={2}>
            <CardMedia
                component="img"
                height="180"
                image={imagepath === '' ? 'images/spaceholder.png' : imagepath}
                onClick={handleOpen}
            />
            <CardContent >
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                <Typography component="p">{year}</Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
            {/* <CardActions>
                <Button size="small">Share</Button>
                <Button size="small">Learn More</Button>
            </CardActions> */}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Paper sx={{
                    width: '80%',
                    height: '80%',
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)',
                    overflow: 'scroll',
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <h1>Gallery</h1>
                    </div>

                    <ResponsiveGallery
                        useLightBox
                        images={gallery}
                    />
                </Paper>
            </Modal>
        </Card>
    );
}
