import React from 'react';
import MediaCard from '../compnents/MediaCard';

const Portfolio = () => {
	return (
		<div
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<h1>Projects</h1>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					paddingLeft: '30px',
					paddingRight: '30px',
					justifyContent: 'center',
				}}>
				<MediaCard
					title={'Clock Tower Control System V2'}
					year={'2022'}
					imagepath={'/images/projects/clocktower2/1.jpg'}
					description={'This is a second version of the Clock Tower Control System made in 2017. The circuit and user interface were both upgraded in this version.'}
					gallery={[
						{
							src: '/images/projects/clocktower2/1.jpg'
						},
						{
							src: '/images/projects/clocktower2/2.jpg'
						},
						{
							src: '/images/projects/clocktower2/3.jpg'
						}]}
				/>
				<MediaCard
					title={'Infinite AI'}
					year={'2022'}
					imagepath={'/images/projects/reskillai/1.png'}
					description={'This is an Object Detection system that covers the entire process of object detection, from image capturing, image labeling to object detection and classification. It can be used on a high end PC or on a Jetson Nano. It was used to train employees at Honeywell Malaysia. It developed during my time at Elliance, together with another intern.'}
					gallery={[
						{
							src: '/images/projects/reskillai/1.png'
						},
						{
							src: '/images/projects/reskillai/2.png'
						},
						{
							src: '/images/projects/reskillai/3.png'
						},
						{
							src: '/images/projects/reskillai/4.png'
						},
						{
							src: '/images/projects/reskillai/5.jpg'
						},
						{
							src: '/images/projects/reskillai/6.png'
						}]}
				/>
				<MediaCard
					title={'E Gem'}
					year={'2022'}
					imagepath={'/images/projects/egem/1.png'}
					description={'This is a library for Semiconductor\'s Equipment Interface Protocol (SECS/GEM). It acts as a bridge between semiconductor manufacturing machines and a host using SECS/GEM. I developed it during my time at Elliance.'}
					gallery={[
						{
							src: '/images/projects/egem/1.png'
						}]}
				/>
				<MediaCard
					title={'Budgeting App V1'}
					year={'2022'}
					imagepath={'/images/projects/budgetingapp/1.png'}
					description={'This is a complete budgeting webapp that allows users to create budgets, add expenses, and view expenses. It is a single page application that uses React and Firebase. Version 2 is currently in development, and will support multiple curreciencies, and an improved analysis algorithm.'}
					gallery={[
						{
							src: '/images/projects/budgetingapp/1.png'
						},
						{
							src: '/images/projects/budgetingapp/2.png'
						}]}
				/>
				<MediaCard
					title={'My Website'}
					year={'2022'}
					imagepath={'/images/projects/mywebsite/1.png'}
					description={'This is my personal website. It\'s a static website that I made using React. You are looking at it right now :)'}
					gallery={[
						{
							src: '/images/projects/mywebsite/1.png'
						}]}
				/>
				<MediaCard
					title={'Sudoku Solver'}
					year={'2020'}
					imagepath={'/images/projects/sudokusolver/1.jpg'}
					description={'This is a python sudoku solver that can be used to solve sudoku puzzles. Simple as that :)'}
					gallery={[
						{
							src: '/images/projects/sudokusolver/1.jpg'
						}]}
				/>
				<MediaCard
					title={'LED Cube'}
					year={'2019'}
					imagepath={'/images/projects/ledcube/1.jpg'}
					description={'This is a RGB LED cube that can be controlled by a mobile device. It can be used to display a variety of information such as a clock, etc.'}
					gallery={[
						{
							src: '/images/projects/ledcube/1.jpg'
						},
						{
							src: '/images/projects/ledcube/2.jpeg'
						}]}
				/>
				<MediaCard
					title={'Giant IoT Clock'}
					year={'2018'}
					imagepath={'/images/projects/hallclock/2.jpg'}
					description={'This is a few meters wide RGB IoT Clock that is made for my high school hall. It\'s time is always accurate.'}
					gallery={[
						{
							src: '/images/projects/hallclock/1.jpg'
						},
						{
							src: '/images/projects/hallclock/2.jpg'
						}]}
				/>
				<MediaCard
					title={'ESP-07 Carrier Board'}
					year={'2018'}
					imagepath={'/images/projects/esp07/1.jpg'}
					description={'This is a carrier board that can be used to control a ESP-07 module. It can be used to control a smart home system, a RGB IoT Clock, a smart security system and many more stuff.'}
					gallery={[
						{
							src: '/images/projects/esp07/1.jpg'
						}]}
				/>
				<MediaCard
					title={'Patient Message Display'}
					year={'2018'}
					imagepath={'/images/projects/patientmessage/1.jpg'}
					description={'This is a device for patients who have sicknesses that impedes their speech ability to communicate. It can display the patient\'s message on a LED dot matrix display.'}
					gallery={[
						{
							src: '/images/projects/patientmessage/1.jpg'
						}]}
				/>
				<MediaCard
					title={'USB Protector'}
					year={'2017'}
					imagepath={'/images/projects/usbprotector/1.jpg'}
					description={'An adapter that protects your phone from USB based attacks such as high voltage and HID attacks.'}
					gallery={[
						{
							src: '/images/projects/usbprotector/1.jpg'
						}]}
				/>
				<MediaCard
					title={'Clock Tower Control System'}
					year={'2017'}
					imagepath={'/images/projects/clocktower/1.png'}
					description={'Control system of the clock tower of Chung Ling Private High School. It is GPS connected hence it is accurate forever.'}
					gallery={[
						{
							src: '/images/projects/clocktower/1.png'
						},
						{
							src: '/images/projects/clocktower/2.jpg'
						},
						{
							src: '/images/projects/clocktower/3.jpg'
						}]}
				/>
				<MediaCard
					title={'Drone Obstacle Avoidance'}
					year={'2017'}
					imagepath={'/images/projects/vitroxdrone/1.png'}
					description={'This is a drone that can avoid obstacles using low cost ultrasonic sensors. It can avoid obstacles in a fairly open area to conduct missions such as landscape surveying.'}
					gallery={[
						{
							src: '/images/projects/vitroxdrone/1.png'
						}]}
				/>
				<MediaCard
					title={'Arduino Learning Shield'}
					year={'2017'}
					imagepath={'/images/projects/arduinoshield/1.jpg'}
					description={'This is a learning shield for Arduino. It can be used to learn the basics of Arduino, including digital, analog inputs, digital outputs and pwm outputs.'}
					gallery={[
						{
							src: '/images/projects/arduinoshield/1.jpg'
						},
						{
							src: '/images/projects/arduinoshield/2.jpg'
						}]}
				/>
				<MediaCard
					title={'iSwing'}
					year={'2016'}
					imagepath={'/images/projects/iswing/1.png'}
					description={'This is a stick with a row of 32 RGB LEDs that can display text and signs when waved.'}
					gallery={[
						{
							src: '/images/projects/iswing/1.png'
						},{
							src: '/images/projects/iswing/2.jpg'
						},{
							src: '/images/projects/iswing/3.jpg'
						},{
							src: '/images/projects/iswing/4.jpg'
						}]}
				/>
				<MediaCard
					title={'Attendance System'}
					year={'2016'}
					imagepath={'/images/projects/studentattendance/1.png'}
					description={'This is a attendance system that can be used at offices or schools. It uses a dual RFID system to automatically track in/out records of personels of a place.'}
					gallery={[
						{
							src: '/images/projects/studentattendance/1.png'
						}]}
				/>
				<MediaCard
					title={'Bench Power Supply'}
					year={'2016'}
					imagepath={'/images/projects/powersupply/1.png'}
					description={'This is a bench power supply that can be used to power a other projects such as a LED cube or tools such as DIY drill.'}
					gallery={[
						{
							src: '/images/projects/powersupply/1.png'
						}]}
				/>
				<MediaCard
					title={'Hydraulic Robot Arm'}
					year={'2015'}
					imagepath={'/images/projects/hydraulicarm/1.png'}
					description={'This is a hydraulic robot arm that uses multiple syringes and pipes to move. It also has an electromagnet to pick up objects.'}
					gallery={[
						{
							src: '/images/projects/hydraulicarm/1.png'
						},
						{
							src: '/images/projects/hydraulicarm/2.jpg'
						},
						{
							src: '/images/projects/hydraulicarm/3.jpg'
						}]}
				/>
			</div>

		</div>
	);
};

export default Portfolio;
