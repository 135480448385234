import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import WorkIcon from '@mui/icons-material/Work';
import Typography from '@mui/material/Typography';
import SchoolIcon from '@mui/icons-material/School';

export default function CustomizedTimeline() {
  return (
    <Timeline position="alternate">
        {/* -------------------------------------------------------------------------------------------------------------- */}
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          2016 Jan - 2020 May
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{backgroundColor:'#FFDD01'}}>
            <SchoolIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Chung Ling Private High School
          </Typography>
          <Typography>IGCSE</Typography>
          <Typography>2020 - Creative Electronics Club, Student Advisor</Typography>
          <Typography>Present - Creative Electronics Club, Alumni Advisor</Typography>
        </TimelineContent>
      </TimelineItem>
      {/* -------------------------------------------------------------------------------------------------------------------- */}
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          2020 Jun - 2021 Nov
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{backgroundColor:'#E40613'}}>
            <SchoolIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Inti International College Penang
          </Typography>
          <Typography>A Level</Typography>
          <Typography>2021 - IICP Pre-U Society, Committee</Typography>
        </TimelineContent>
      </TimelineItem>
      {/* -------------------------------------------------------------------------------------------------------------------- */}
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          2021 Nov - 2022 Jun
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{backgroundColor:'#1F1B72'}}>
            <WorkIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Elliance
          </Typography>
          <Typography>Internship(Software Developer)</Typography>
          <Typography>MyReskill AI - Developer and Trainer</Typography>
        </TimelineContent>
      </TimelineItem>
      {/* ----------------------------------------------------------------------------------------------- */}
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          2022 Sep - 2025 Sep
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{backgroundColor:'#E40613'}}>
            <SchoolIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            King's College London
          </Typography>
          <Typography>BSc Physics</Typography>
          <Typography>2022 - Maxwell Society, Member</Typography>
          <Typography>2022 - Space Society, Member</Typography>
          <Typography>2022 - Tech Society, Member</Typography>
          <Typography>2022 - Brazilian Jiu Jitsu, Member</Typography>
          <Typography>2023 - SPIE LIONS, Member</Typography>
        </TimelineContent>
      </TimelineItem>
      {/* -------------------------------------------------------------------------------------------------------------------- */}
      
      
    </Timeline>
  );
}
