import React from 'react';
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';

const About = () => {
	return (
		<div>
			<h1>About Me</h1>
			<Box
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					paddingLeft: '10%',
					paddingRight: '10%',
					// '& > :not(style)': {
					// 	m: 1,
					// 	width: 128,
					// 	height: 128,
					// },
				}}
			>
				{/* <Paper elevation={0} /> */}
				{/* <Paper elevation={4}
					sx={{
						padding: '10px',
						justifyContent: 'center',
					}}> */}
				<p align="justify"> As a physics undergraduate student and a skilled <font color="red">full-stack developer
				</font>, I've accumulated over seven years of programming experience. My work
					history includes hands-on experience in <font color="purple">Semiconductor Machine Communication </font>
					and <font color="blue">machine vision</font>. Alongside this, I've designed and coded mini-games,
					developed <font color="blue">machine learning</font> algorithms, created computer vision tools, built
					various tools, and developed firmware.
				</p>
				<p align="justify"> Aside from programming, I'm also proficient in 3D modeling, <font color="brown">3D printing</font>, and
					laser cutting/engraving. I have extensive experience working with modern
					technologies like WiFi, Bluetooth, GPS, and various sensors. I often utilize
					Arduino microcontrollers, ESP32s, and <font color="green">Raspberry Pis</font> to design and develop electronic
					projects both individually and as a team since 2016.</p>
				<p align="justify">
					As a hobby, I've been delving into cybersecurity research since 2017. This interest has
					given me valuable experience in networking and using Linux systems. Additionally, I also do
					freelance 3D drawings using <font color="orange">Blender</font>, showcasing my creative skills. In my free time, I'm
					learning Brazilian Jiujitsu to keep myself active and maintain a healthy work-life balance.</p>
				{/* </Paper> */}
			</Box>

		</div>
	);
};

export default About;
